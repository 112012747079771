<template>
  <div
    id="organization"
    class="contenedor"
    style="background-color: #fefeff !important"
  >
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container class="base text-center">
      <v-row
        no-gutters
        class="titulo ml-10"
        align-content="center"
        justify="center"
        >ORGANIGRAMA EMPRESARIAL</v-row
      >

      <v-row no-gutters justify="start" class="mt-3 blackList">
        <v-col cols="12" lg="4" md="4" sm="4" xs="4">
          <label for="enterprise">Razón Social</label>
          <select
            name="enterprise"
            id="enterprise"
            v-model="enterprise"
            @change="cambioEmpresa()"
          >
            <option selected disabled value="0">Selecciona Empresa</option>
            <option
              v-for="enterprise in enterprises"
              :key="enterprise.id"
              v-bind:value="enterprise.id"
            >
              {{ enterprise.razonSocial }}
            </option>
          </select>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" xs="4">
          <label for="organigramaPor">Organigrama por</label>
          <select
            name="organigramaPor"
            id="organigramaPor"
            v-model="organigramaPor"
            @change="solicitud()"
          >
            <option selected disabled value="0">Seleccionar</option>
            <option value="1">Áreas</option>
            <option value="2">Empleados</option>
          </select>
        </v-col>
      </v-row>
      <v-row> <v-col cols="12" lg="12" sm="12" md="12"> </v-col></v-row>

      <v-card class="mx-auto" elevation="4">
        <div
          id="diagrama"
          class="letraTablaChart"
          style="justify: center !important; overflow: scroll; display:none"
        >
          <GChart
            :settings="{
              packages: ['orgchart'],
              callback: () => {
                this.drawChart();
              },
            }"
            type="OrgChart"
            :data="chartData"
            allowCollapse="true"
          />
        </div>
      </v-card>
      <v-row> <v-col cols="12" lg="12" sm="12" md="12"> </v-col></v-row
      ><v-card class="mx-auto" elevation="4">
        <div
          id="diagramaArea"
          class="letraTablaChart"
          style="justify: center !important; overflow: scroll; display:none"
        >
          <GChart
            :settings="{
              packages: ['orgchart'],
              callback: () => {
                this.drawArea();
              },
            }"
            type="OrgChart"
            :data="chartArea"
            allowCollapse="true"
          /></div
      ></v-card>
    </v-container>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
//import { GChart } from "vue-google-charts";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      expiration: false,
      chartData: null,
      chartArea: null,
      empleados: [],
      employees: [],
      enterprise: localStorage.empresaIdGlobal,
      enterprises: [],
      todasAreas: [],
      organigramaPor: 0,
      auxi: [],
    };
  },
  methods: {
    cambioEmpresa() {
      document.getElementById("diagramaArea").style.display = "none";
      document.getElementById("diagrama").style.display = "none";
      this.organigramaPor = 0;
      this.empleados = [];
      this.areas = [];
    },
    drawChart() {
      this.chartData = new google.visualization.DataTable();
      this.chartData.addColumn("string", "Name");
      this.chartData.addColumn("string", "Manager");
      this.chartData.addRows(this.empleados);
      // Create the chart.
      var chart = new google.visualization.OrgChart(
        document.getElementById("diagrama")
      );
      // Draw the chart, setting the allowHtml option to true for the tooltips.
      chart.draw(this.chartData, {
        allowHtml: true,
        allowCollapse: true,
        color: "#fbc02d",
        selectionColor: "#bdbdbd",
        size: "large",
      });
    },
    drawArea() {
      this.chartArea = new google.visualization.DataTable();
      this.chartArea.addColumn("string", "Name");
      this.chartArea.addColumn("string", "Manager");
      this.chartArea.addRows(this.auxi);
      // Create the chart.
      var chart = new google.visualization.OrgChart(
        document.getElementById("diagramaArea")
      );
      // Draw the chart, setting the allowHtml option to true for the tooltips.
      chart.draw(this.chartArea, {
        allowHtml: true,
        allowCollapse: true,
        color: "#fbc02d",
        selectionColor: "#bdbdbd",
        size: "large",
      });
    },
    filtradoAreas() {
      this.areas = [];
      console.log(this.employees);
      var aux = this.employees.forEach((value, index) => {
        if (value.puesto.puestoReportaId === null) {
          this.areas.push([
            {
              v: value.area.nombreArea,
              f: value.area.nombreArea,
            },
            "",
          ]);
          console.log("areas que no reporta");
          console.log(this.areas);
        }
        if (value.puesto.puestoReportaId != null) {
          var auxiliarReporta = this.employees.forEach((val, index) => {
            if (val.puesto.id === value.puesto.puestoReportaId) {
              this.areas.push([
                {
                  v: value.area.nombreArea,
                  f: value.area.nombreArea,
                },
                val.area.nombreArea,
              ]);
            }
            console.log("areas que reporta");
            console.log(this.areas);
          });
        }
      });
      document.getElementById("diagramaArea").style.display = "block";
      this.drawArea();
      this.show = false;
    },
    filtrado() {
      //console.log("entro a filtrado");
      this.empleados = [];
      var aux = this.employees.forEach((value, index) => {
        if (value.puesto.puestoReportaId === null) {
          this.empleados.push([
            {
              v:
                value.nombre +
                " " +
                value.apellidoPaterno +
                " " +
                value.apellidoMaterno,
              f:
                value.nombre +
                " " +
                value.apellidoPaterno +
                " " +
                value.apellidoMaterno +
                '<div style="color:#a1887f; font-style:italic;">' +
                value.puesto.nombrePuesto +
                "</div>",
            },
            "",
          ]);
        }
        if (value.puesto.puestoReportaId != null) {
          var auxiliarReporta = this.employees.forEach((val, index) => {
            if (val.puesto.id === value.puesto.puestoReportaId) {
              this.empleados.push([
                {
                  v:
                    value.nombre +
                    " " +
                    value.apellidoPaterno +
                    " " +
                    value.apellidoMaterno,
                  f:
                    value.nombre +
                    " " +
                    value.apellidoPaterno +
                    " " +
                    value.apellidoMaterno +
                    '<div style="color:#a1887f; font-style:italic; font-size: 0.55rem ">' +
                    value.puesto.nombrePuesto +
                    "</div>",
                },
                val.nombre +
                  " " +
                  val.apellidoPaterno +
                  " " +
                  val.apellidoMaterno,
              ]);
            }
          });
        }
      });
      document.getElementById("diagrama").style.display = "block";
      this.drawChart();
      this.show = false;
    },
    obtenerAreas() {
      //api para area por id de empresa
      this.todasAreas = [];
      this.show = true;
      axios
        .get(Server + "/Organization/ObtenerAreas/" + this.enterprise, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.todasAreas = response.data;
          this.areass();
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    areass() {
      this.auxi = [];
      var auxiliar = this.todasAreas.forEach((value, index) => {
        if (value.nivelJerarquico === 1) {
          this.auxi.push([
            {
              f: value.nombreArea,
              v: value.nombreArea,
            },
            "",
          ]);
        }
        if (value.nivelJerarquico != 1) {
          var areaReporta = this.todasAreas.forEach((val, index) => {
            //console.log(value.nivelJerarquico);
            var u = value.nivelJerarquico - 1;
            //console.log(u);
            if (u == val.nivelJerarquico) {
              this.auxi.push([
                {
                  f: value.nombreArea,
                  v: value.nombreArea,
                },
                val.nombreArea,
              ]);
            }
          });
        }
      });
      //console.log("para dra");
      //console.log(this.auxi);
      document.getElementById("diagramaArea").style.display = "block";
      this.drawArea();
      this.show = false;
      //console.log("saldre");
    },
    solicitud() {
      if (this.organigramaPor == 1) {
        console.log("areas");
        document.getElementById("diagrama").style.display = "none";
        this.obtenerAreas();
      }
      if (this.organigramaPor == 2) {
        console.log("empleados");
        document.getElementById("diagramaArea").style.display = "none";
        this.obtenerEmpleados();
      }
    },
    obtenerEmpleados() {
      this.show = true;
      axios
        .get(Server + "/organization/" + this.enterprise, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.employees = response.data;
          this.filtrado();
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    listar() {
      this.show = true;
      axios
        .get(Server + "/empresas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.enterprises = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
  },
  created() {
    this.listar();
  },
};
</script>
<style scoped>
.google-visualization-orgchart-nodesel {
  border: 2px solid #ffd52b !important;
}
table.google-visualization-orgchart-table {
  margin: 0 auto;
}
</style>